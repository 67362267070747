import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { withNamespaces } from "react-i18next";

import "../../i18n";
import ContentLayout from "../../components/contentLayout";

const SteadfastPage = ({ t, data }) => {
  return (
    <ContentLayout>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content">
                <h1 className="title is-2 center">
                  A No-Recommendation YouTube Interface
                </h1>
                <h4 className="subtitle center">
                  <a
                    href="https://steadfast.veritable.pw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://steadfast.veritable.pw/
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <a
                href="https://steadfast.veritable.pw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.image1.childImageSharp.fixed}
                  alt="Steadfast Landing Page"
                />
              </a>
            </div>
          </div>
          <div className="columns is-multiline is-tablet">
            <div className="column is-10-desktop is-offset-1-desktop is-half-widescreen is-offset-3-widescreen">
              <div className="content">
                <p>{t("steadfast-paragraph-1")}</p>
                <p>{t("steadfast-paragraph-2")}</p>
                <p>{t("steadfast-paragraph-3")}</p>
                <p>{t("technology-used")}</p>
                <ul>
                  <li>
                    Database
                    <ul className="inner">
                      <li>Firebase (user authentication)</li>
                      <li>SQLite (channel and video information)</li>
                      <li>Redis (user information)</li>
                    </ul>
                  </li>
                  <li>
                    Web Interface
                    <ul className="inner">
                      <li>
                        <a
                          href="https://nextjs.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Next.js
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <div className="box">
                <a
                  href="https://steadfast.veritable.pw/channel?id=UUOjD18EJYcsBog4IozkF_7w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={data.image2.childImageSharp.fixed}
                    alt="Timeline of PyData"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContentLayout>
  );
};

export default withNamespaces(["projects", "navbar"], {})(SteadfastPage);

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "steadfast-frontpage.png" }) {
      childImageSharp {
        fixed(width: 700) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "steadfast-timeline.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
